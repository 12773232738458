import React, { useState, useEffect } from "react";
import axios from "axios";

import { endpoint } from '../../compFunc/endpoint';

const EventPage = () => {
  const [events, setEvents] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/events/`
        );
        setEvents(response.data.results);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const openModal = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const tabStyle = {
    display: "inline-block",
    padding: "10px 20px",
    cursor: "pointer",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginRight: "10px",
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: "#07bc0c",
    color: "#fff",
  };

  const eventsToDisplay = events.filter((event) =>
    activeTab === "upcoming" ? !event.is_archived : event.is_archived
  );

  return (
    <section className="events pt-130 pb-100">
      <div className="container">
        <h1 className="section-title">Events</h1>
        <div className="tabs mt-4 mb-4" style={{ marginBottom: "20px" }}>
        <span
            style={activeTab === "past" ? activeTabStyle : tabStyle}
            onClick={() => handleTabChange("past")}
          >
            Past Events
          </span>
          
          <span
            style={activeTab === "upcoming" ? activeTabStyle : tabStyle}
            onClick={() => handleTabChange("upcoming")}
          >
            Upcoming Events
          </span>
          
        </div>
        <div className="row">
          {eventsToDisplay.map((event) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={event.id}>
              <div
                className="eventCard"
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "15px",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <img
                  src={`https://res.cloudinary.com/deofjoqll/${event.cover_image}`}
                  alt={event.title}
                  style={{
                    width: "100%",
                    height: "180px",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                />
                <h3
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    margin: "15px 0",
                  }}
                >
                  {event.title}
                </h3>
                <p style={{ color: "#666", fontSize: "14px" }}>
                  {event.location}
                </p>
                <p style={{ color: "#999", fontSize: "12px" }}>
                  {new Date(event.event_date).toDateString()}
                </p>
                <button
                  onClick={() => openModal(event)}
                  style={{
                    marginTop: "10px",
                    padding: "10px 15px",
                    backgroundColor: "#07bc0c",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Modal */}
        {selectedEvent && (
            <div
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            >
            <div
                style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "20px",
                maxWidth: "600px",
                width: "90%",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)",
                }}
            >
                <h2 style={{ marginBottom: "10px" }}>{selectedEvent.title}</h2>
                <p
                dangerouslySetInnerHTML={{ __html: selectedEvent.description }}
                style={{ marginBottom: "15px" }}
                ></p>
                <p>
                <strong>Location:</strong> {selectedEvent.location}
                </p>
                <p>
                <strong>Date:</strong>{" "}
                {new Date(selectedEvent.event_date).toDateString()}
                </p>
                <button
                onClick={closeModal}
                style={{
                    marginTop: "15px",
                    padding: "10px 15px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                }}
                >
                Close
                </button>
            </div>
            </div>
        )}
        </div>
    </section>
);
};

export default EventPage;
