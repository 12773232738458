import React, { Fragment } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import PressReleaseAndReport from '../../components/PressReleasesAndReports/PressReleaseAndReport';
import Footer from '../../components/footer/Footer';


const PressReleaseAndReportPage = (props) => {

    return (
        <Fragment>
            <Header2 hclass={'header--styleFour'} />
            <main className="main">
                <PageTitle pageTitle={'Press Releases and Reports'} pagesub={'Press Release'}
                    pageTop={'Our'}/>
                <PressReleaseAndReport />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PressReleaseAndReportPage;
