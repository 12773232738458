import React from 'react';

const Newsletter = () => {

  return (
    <div>
      <h2 className='text-light' >Subscribe to Our Newsletter</h2>
      <p className='text-light mt-2 mb-2'>
        Click the button below to subscribe and stay updated with the latest events and reports.
      </p>
      <button className='btn btn--styleOne btn--primary it-btn'>
        <a className='text-light text-decoration-none' href="https://zc.vg/u5nxP" target='_blank'>
        Subscribe </a>
      </button>
    </div>
  );
};

export default Newsletter;
