import React, { useEffect, useState } from "react";
import axios from "axios";

import { endpoint } from '../../compFunc/endpoint';

const PressReleaseAndReport = () => {
  const [pressReleases, setPressReleases] = useState([]);
  const [reports, setReports] = useState([]);
  const [activeTab, setActiveTab] = useState("press");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${endpoint}/api/press/`
        );
        const data = response.data.results;

        const pressData = data.filter((item) => item.update_type === "Press Release");
        const reportData = data.filter((item) => item.update_type === "Report");

        setPressReleases(pressData);
        setReports(reportData);
      } catch (error) {
        console.error("Error fetching press and report data:", error);
      }
    };
    fetchData();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    window.scrollTo(0, 0);
  };

  const tabStyle = {
    display: "inline-block",
    padding: "10px 20px",
    cursor: "pointer",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginRight: "10px",
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: "#07bc0c",
    color: "#fff",
  };

  return (
    <section className="press-and-report pt-130 pb-100">
      <div className="container">
        <h1 className="section-title">Press Releases & Reports</h1>
        <div className="tabs mt-4 mb-4" style={{ marginBottom: "20px" }}>
          <span
            style={activeTab === "press" ? activeTabStyle : tabStyle}
            onClick={() => handleTabChange("press")}
          >
            Press Releases
          </span>
          <span
            style={activeTab === "report" ? activeTabStyle : tabStyle}
            onClick={() => handleTabChange("report")}
          >
            Reports
          </span>
        </div>
        <div className="content">
          {activeTab === "press" && (
            <div className="row">
              {pressReleases.map((item) => (
                <div
                  className="col-lg-6 col-md-6 col-sm-12 mb-4"
                  key={item.id}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="card"
                    style={{
                      width: "100%",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "20px",
                      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                    }}
                  >
                    <h2 className="card-title" style={{ fontSize: "1.25rem" }}>
                      {item.title}
                    </h2>

                    <div 
                        className="donationDetails__text storiesDetails__text flex flex-col gap-[2em] mb-30"
                        dangerouslySetInnerHTML={{ __html: `${item.body.substring(0, 150)} ....` }}> 
                    </div> 
                    <a
                      href={item.press_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        backgroundColor: "#007bff",
                        padding: "10px 15px",
                        borderRadius: "5px",
                      }}
                    >
                      Read More
                    </a>
                    <div
                      className="meta"
                      style={{
                        marginTop: "15px",
                        fontSize: "0.8rem",
                        color: "#666",
                      }}
                      >
                        <span>By {item.user}</span> |{" "}
                        <span>{new Date(item.date_posted).toDateString()}</span>
                      </div>
                      </div>
                      </div>
                      ))}
                      </div>
                      )}
                      {activeTab === "report" && (
                      <div className="row">
                      {reports.map((item) => (
                      <div
                      className="col-lg-6 col-md-6 col-sm-12 mb-4"
                      key={item.id}
                      style={{ display: "flex", justifyContent: "center" }}
                      >
                      <div
                      className="card"
                      style={{
                        width: "100%",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        padding: "20px",
                        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                      }}
                      >
                      <h2 className="card-title" style={{ fontSize: "1.25rem" }}>
                        {item.title}
                      </h2>
                      <p
                        className="card-body"
                        style={{ fontSize: "0.9rem", marginBottom: "1rem" }}
                      >
                        {item.body.substring(0, 150)}...
                      </p>
                      {item.press_url && (
                        <a
                          href={item.press_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary"
                          style={{
                            textDecoration: "none",
                            color: "#fff",
                            backgroundColor: "#07bc0c",
                            padding: "10px 15px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        >
                          Read More
                        </a>
                      )}
                      {item.press_url_two && (
                        <a
                          href={item.press_url_two}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-secondary"
                          style={{
                            textDecoration: "none",
                            color: "#fff",
                            backgroundColor: "#07bc0c",
                            padding: "10px 15px",
                            borderRadius: "5px",
                          }}
                        >
                          Alternate Link
                        </a>
                      )}
                      <div
                        className="meta"
                        style={{
                          marginTop: "15px",
                          fontSize: "0.8rem",
                          color: "#666",
                        }}
                      >
                        <span>By {item.user}</span> |{" "}
                        <span>{new Date(item.date_posted).toDateString()}</span>
                </div>
                </div>
              </div>
              ))}
              </div>
              )}
          </div>
        </div>
        </section>
      );
    };

export default PressReleaseAndReport;
