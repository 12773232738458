import React from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Events from '../../components/Events/Events';
import Footer from '../../components/footer/Footer';

const EventPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };


    return (
        <React.Fragment>
            <Header2 hclass={'header--styleFour'} />
            <main className="main">
                <PageTitle pageTitle={'Events'} pagesub={'Events'} />
            </main>
            <Events />
            <Footer />
            <Scrollbar />
        </React.Fragment>
    );
};

export default EventPage;
