import React from 'react'
import { Link } from 'react-router-dom'

import shape from '../../images/shapes/footerShape2.png'
import logo from '../../images/logos/Logo.png'

import Newsletter from './Newsletter'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const SubmitHandler = (e) => {
    e.preventDefault()
}


const Footer = (props) => {
    return (
        <footer className="footer footer--bg footer--styleOne pt-70 pb-40">
            <img src={shape} alt="EDGSO Shape" className="footer__shape" />
            <div className="container">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="footer__logo">
                            <img src={logo} alt="EDGSO Logo" className="footer__logo__image" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="footer__social itSocial">
                            <ul>
                                <li>
                                    <a onClick={ClickHandler} className="facebook" target='_blank'
                                        href="https://www.facebook.com/Enablersngo" rel="nofollow">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={ClickHandler} className="youtube" target='_blank'
                                        href="https://youtube.com/@enablersdiasporagrassroots7582?si=VLS8EPbOe1bPLhLc"
                                        rel="nofollow">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={ClickHandler} className="instagram" target='_blank'
                                        href="https://www.instagram.com/worldwideenablers/profilecard/?igsh=dWpidzF3NXlsd29s"
                                        rel="nofollow">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <a onClick={ClickHandler} className="linkedin" target='_blank'
                                    href="/" rel="nofollow">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <hr className="footer__line" />
                    </div>
                </div>
                <div className="row">
                    <div className="footer__middle pt-65 pb-35">
                        <div className="row justify-content-between">
                            <div className="col-lg-2 col-md-4 mb-30">
                                <div className="footer__widget">
                                    <div className="footer__title">
                                        <h2 className="footer__heading text-uppercase text-white">About us</h2>
                                    </div>
                                    <div className="footer__menu">
                                        <ul>
                                            {/* <li><Link onClick={ClickHandler} to="/contact">Policy Priorities</Link></li> */}
                                            {/* <li><Link onClick={ClickHandler} to="/contact"></Link></li> */}
                                            <li><Link onClick={ClickHandler} to="/about"> About </Link></li>
                                            <li><Link onClick={ClickHandler} to="/terms"> Terms </Link></li>
                                            <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mb-30">
                                <div className="footer__widget">
                                    <div className="footer__title">
                                        <h2 className="footer__heading text-uppercase text-white">Support us</h2>
                                    </div>
                                    <div className="footer__menu">
                                        <ul>
                                            <li><Link onClick={ClickHandler} to="/donation">Donate Now</Link></li>
                                            <li><Link onClick={ClickHandler} to="/press"> Press Releases and Reports </Link> </li>
                                            <li><Link onClick={ClickHandler} to="/privacy">Privacy & Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mb-30">
                                <div className="footer__widget">
                                    <div className="footer__title">
                                        <h2 className="footer__heading text-uppercase text-white">Quick LInks</h2>
                                    </div>
                                    <div className="footer__menu">
                                        <ul>
                                            <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                                            <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                            <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                            <li><Link onClick={ClickHandler} to="/donation">Donation</Link></li>
                                            <li><Link onClick={ClickHandler} to="/volunteers">Become a Volunteer</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <Newsletter />

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="footer__bottom">
                        <div className="row">
                            <div className="col-12">
                                <hr className="footer__line" />
                            </div>
                            <div className="col mb-20">
                                <div className="footer__copyright pt-20">
                                    <p className="footer__copyright__text mb-0">
                                        Copyright @Enablers World 2024 all right reserved 
                                    </p>
                                    <p> Built with <i className="fas fa-heart text-danger"></i> by <strong> Opera Ocean </strong> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
